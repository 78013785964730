<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
    v-if="userId && admin"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        md="10"
      >
        <base-material-card color="#174282">
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              Extraction
            </div>
          </template>
          <v-btn
           color="green"
           @click="exportExcel">
            Extraire
          </v-btn>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { httpGet } from '@/plugins/axios'
  export default {
    name: 'extraction',
    data () {
      return {
        userId: null,
        admin: null,
      }
    },
    mounted () {
      this.userId = this.$store.state.userId
      this.admin = this.$store.state.isAdmin
    },
    methods: {
      async exportExcel () {
        const response = await httpGet('exportExcel')
        if(response.Result === "OK"){
          console.log(response)
          this.download(response.Users.file)
        }
      },
    getNow () {
        const today = new Date();
        const date = today.getDate() + '-' +(today.getMonth()+1);
        const time = today.getHours() + "h" + today.getMinutes();
        const dateTime = date +' '+ time;
        return dateTime;
    },
      download(file) {
        const linkSource = file;
        const downloadLink = document.createElement("a");
        const fileName = "Base Les avocats Prennent La Défense" + this.getNow();
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      },
    },

  }
</script>
